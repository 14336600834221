import React, { useState, useContext } from "react"
import styled from "styled-components"

import useFormatLocale from "@/hooks/useFormatLocale"
import Image from "@/components/Image"
import plantBg from "../../icons/artwork.svg"

const PlantCard = ({ plant }) => {
  const lng = useFormatLocale()
  const { name, icon } = plant
  return (
    <StyledPlantCard bg={plantBg}>
      <div
        style={{
          borderRadius: `2px`,
          boxShadow: `0 0 0 6px #fefdf0`,
          backgroundColor: `#fefdf0`,
        }}
      >
        <div className="plant-bg">
          <div className="plant-icon">
            <Image src={icon.childImageSharp.fluid} />
          </div>
          <div className="plant-storage">{/* <CheckIcon /> */}</div>
          <div className="plant-checkbox">
            {/* <CheckMarkIcon isMark={isOwned} color={styledCard} /> */}
          </div>
        </div>
      </div>
      <span className="plant-title">{name[lng]}</span>
    </StyledPlantCard>
  )
}

export default PlantCard

const StyledPlantCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  .plant-bg {
    width: 124px;
    height: 184px;
    background-size: cover;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 4px;
    background-image: url(${(props) => props.bg});
    .plant-icon {
      cursor: pointer;
      width: 72%;
      &.large{
        width: 100%;
      }
      &.small{
        width: 57%;
      }
      & > div {
        width: 100%;
      }
    }
  }
  .plant-title {
    margin-top: 18px;
  }
`