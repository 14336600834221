import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "@/components/Layout"
import Image from "@/components/Image"
import HorizontalRule from "@/components/HorizontalRule"
import PlantCard from "@/components/PlantCard"

const LeifGardenPage = () => {
  const queryData = useStaticQuery(graphql`
    query {
      allLeifYaml {
        nodes {
          name
          avatar {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          flowers{
            name {
              en
              ja
              ko
              zh_hans
              zh_hant
            }
            icon {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          bushes{
            name {
              en
              ja
              ko
              zh_hans
              zh_hant
            }
            icon {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          trees{
            name {
              en
              ja
              ko
              zh_hans
              zh_hant
            }
            icon {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          resources{
            name {
              en
              ja
              ko
              zh_hans
              zh_hant
            }
            icon {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  const { flowers,bushes,trees,resources,...LeifInfo } = queryData.allLeifYaml.nodes[0]
  return(
    <Layout>
      <PageContainer>
        <div className="header">
          <Image className="img" src={LeifInfo.avatar.childImageSharp.fluid} />
          <span>然然植物园</span>
        </div>
        <PlantCards plants={flowers} />
        <HorizontalRule />
        <PlantCards plants={bushes} />
        <HorizontalRule />
        <PlantCards plants={trees} />
        <HorizontalRule />
        <PlantCards plants={resources} />
      </PageContainer>
    </Layout>
  )
}

export default LeifGardenPage

const PageContainer = styled.div`
  padding: 0 32px;
  min-height: calc(100vh - 72px - 116px);
  .header{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 14px 0 18px;
    .img{
      width: 150px;
      border-radius: 50%;
    }
    span{
      margin: 14px 0 18px;
    }
  }
`

const PlantCards = ({ plants }) => {
  return (
    <StyledPlantCards>
      <div className="plant_cards">
        {plants.map((plant) => (
          <PlantCard plant={plant} key={plant.name.en} />
        ))}
      </div>
    </StyledPlantCards>
  )
}

const StyledPlantCards = styled.div`
  .plant_cards {
    display: flex;
    flex-wrap: wrap;
  }
`